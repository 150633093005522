/*$icon-font-path: "../node_modules/bootstrap-sass/assets/fonts/bootstrap/";
@import "../node_modules/bootstrap/scss/bootstrap";
// $fa-font-path: "../node_modules/font-awesome/fonts";
// @import "../node_modules/font-awesome/scss/font-awesome";
@import "../node_modules/font-awesome/css/font-awesome.css";
@import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/ngx-toastr/toastr.css";
@import "../node_modules/leaflet/dist/leaflet.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

@font-face {
  font-family: 'MoonHouse';
  src: url('./assets/fonts/moonhouse.eot');
  src: url('./assets/fonts/moonhouse.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/moonhouse.ttf') format('truetype');
}

@import "app/theme/styles/variables";
@import "app/theme/styles/mixins";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/base";

@-webkit-keyframes trans1 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-250px,0,0);
  }
}

@-moz-keyframes trans1 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-250px,0,0);
  }
}

@keyframes trans1 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-250px,0,0);
  }
}

@-webkit-keyframes trans2 {
  from {
    transform: translate3d(-160px,0,0);
  }

  to {
    transform: translate3d(53px,0,0);
  }
}

@-moz-keyframes trans2 {
  from {
    transform: translate3d(-160px,0,0);
  }

  to {
    transform: translate3d(53px,0,0);
  }
}

@keyframes trans2 {
  from {
    transform: translate3d(-160px,0,0);
  }

  to {
    transform: translate3d(53px,0,0);
  }
}

@-webkit-keyframes trans3 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-220px,0,0);
  }
}

@-moz-keyframes trans3 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-220px,0,0);
  }
}

@keyframes trans3 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-220px,0,0);
  }
}

body, container, div {
  font-family: Roboto;
  letter-spacing: -0.8px;
}
@media only screen and (max-width: 600px) {
  body, container, div {
    margin-top: 0.3rem;
  }
}

body {
  background-color: #fff !important
}

@media only screen and (max-width: 600px) {
  .p-component, .p-component * {
    box-sizing: border-box;
    margin-left: 14% !important;
  }

  .breadcrumb {
    padding: 0.5rem 1rem;
    font-size: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .breadcrumb {
    padding: 0.5rem 1rem;
    font-size: 11px;
    padding-left: 1rem;
    max-height: 35px;
    width: 312px;
    margin-left: 0rem;
    margin-right: 42rem;
  }
  }
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  opacity: 0.5;
  transition: opacity 0.6s ease;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffc2b3;
  background-clip: padding-box;
  border-top: 0px solid transparent;
  border-bottom: 0px solid transparent;
}

    .nav-link {
      color: #000;
    }

    .loader-outer {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999;
      background: #fff;
      visibility: visible;
      opacity: 1;
      -webkit-transition: visibility 0.5s, opacity 0.3s linear;
      -moz-transition: visibility 0.5s, opacity 0.3s linear;
      transition: visibility 0.5s, opacity 0.3s linear;

      &.hide {
        visibility: hidden;
        opacity: 0;
      }

      .loader-container {
        height: 100%;
        width: 100%;
        display: table;

        .loader-inner {
          vertical-align: middle;
          height: 100%;
          display: table-cell;

          .loader {
            width: 80px;
            margin: auto;
            position: relative;
            height: 60px;

            .mask {
              position: absolute;
              overflow: hidden;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              border-radius: 5px;

              .plane {
                background: #DD1B16;
                width: 400%;
                height: 100%;
                position: absolute;
              }
            }

            #top {
              width: 53px;
              height: 15px;
              left: 20px;
              -webkit-transform: skew(-15deg, 0);
              -moz-transform: skew(-15deg, 0);
              transform: skew(-15deg, 0);

              .plane {
                -webkit-animation: trans1 1.3s ease-in infinite 0s backwards;
                -moz-animation: trans1 1.3s ease-in infinite 0s backwards;
                animation: trans1 1.3s ease-in infinite 0s backwards;
              }
            }

            #middle {
              width: 33px;
              height: 15px;
              left: 20px;
              top: 15px;
              -webkit-transform: skew(-15deg, 40deg);
              -moz-transform: skew(-15deg, 40deg);
              transform: skew(-15deg, 40deg);

              .plane {
                transform: translate3d(0px,0,0);
                background: #DD1B16;
                -webkit-animation: trans2 1.3s linear infinite 0.3s backwards;
                -moz-animation: trans2 1.3s linear infinite 0.3s backwards;
                animation: trans2 1.3s linear infinite 0.3s backwards;
              }
            }

            #bottom {
              width: 53px;
              height: 15px;
              top: 30px;
              -webkit-transform: skew(-15deg, 0);
              -moz-transform: skew(-15deg, 0);
              transform: skew(-15deg, 0);

              .plane {
                -webkit-animation: trans3 1.3s ease-out infinite 0.7s backwards;
                -moz-animation: trans3 1.3s ease-out infinite 0.7s backwards;
                animation: trans3 1.3s ease-out infinite 0.7s backwards;
              }
            }

            p.loader-text {
              color: #000;
              position: absolute;
              top: 42px;
              font-family: 'MoonHouse';
              font-size: 16px;

              span {
                color: #DD1B16;
              }
            }
          }
        }
      }
    }


    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }

    ::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border: 0px none #ffffff;
      border-radius: 0px;

      &:hover {
        background: #cccccc;
      }

      &:active {
        background: #888888;
      }
    }

    ::-webkit-scrollbar-track {
      background: #666666;
      border: 0px none #ffffff;
      border-radius: 0px;

      &:hover {
        background: #666666;
      }

      &:active {
        background: #333333;
      }
    }

    ::-webkit-scrollbar-corner {
      background: transparent;
    }

    .p-autocomplete {
      width: 225px;
      height: 25px;
      margin-top: 0.9rem;
    }

    .p-inputtext {
      margin: 0;
        width: 240px; !important;
      background-size: 18px 19px !important;
      background-position: right !important;
      background-image: url('assets/icons/search.gif') !important;
      background-repeat: no-repeat !important;
    }
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 80px;
  right: 0;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  margin-right: 3rem;
  margin-bottom: -3rem;
}

.fa-w {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  margin-top: 14px;
  padding-left: 1px;
}*/
$icon-font-path: "../node_modules/bootstrap-sass/assets/fonts/bootstrap/";
@import "../node_modules/bootstrap/scss/bootstrap";
@import '~bootstrap/dist/css/bootstrap.min.css';
// $fa-font-path: "../node_modules/font-awesome/fonts";
// @import "../node_modules/font-awesome/scss/font-awesome";
@import "../node_modules/font-awesome/css/font-awesome.css";
@import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/ngx-toastr/toastr.css";
@import "../node_modules/leaflet/dist/leaflet.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import '~primeicons/primeicons.css';
@import '~primeng/resources/themes/saga-blue/theme.css';
@import '~primeng/resources/primeng.min.css';


@font-face {
    font-family: 'MoonHouse';
    src: url('./assets/fonts/moonhouse.eot');
    src: url('./assets/fonts/moonhouse.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/moonhouse.ttf') format('truetype');
}

@import "app/theme/styles/variables";
@import "app/theme/styles/mixins";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/base";

body, p, div, container {
    font-family: Poppins !important;
    letter-spacing: 0.2px;
}

/*.app.sidebar-fixed .main-content {
  padding-left: 263px !important;
}*/
.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 1.25ch;
  height: 1.25ch;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}
.p-toast-top-center {
    left: 50%;
    margin-left: -10em;
    top: 130px !important;
    background-color: #fff;
}

.p-autocomplete {
    display: inline-flex;
    position: relative;
    width: 90% !important;
}

.app.combined .wrapper, .app.combined .wrapper-inner {
    /*background-color: #ffffff !important;*/
    color: #080808 !important;
}

.breadcrumb {
  margin-top: 1%;
  margin-left: -26px;
  margin-right: -26px;
}

.main-content {
    width: 99% !important;
    -webkit-box-flex: 1;
    min-height: calc(100vh - (46px + 28px));
    overflow: hidden;
}

/*body .ui-table .ui-table-tbody > tr {
    background-color: #ffffff;
    color: #333333;
    FONT-SIZE: 13px;
}*/
/*p-table making header padding null*/
/*body .ui-table .ui-table-caption {
    border-bottom: 0 none;
    font-weight: 700;
    padding-bottom: 0px;
    padding-top: 0px;
}*/
/*p-table making header padding null*/
/*.ui-table .ui-table-tbody > tr:hover {
    background-color: #7ad2f7 !important;
    color: #333333;

    line-height: 18px !important;
    cursor: pointer !important;
}*/

/*.global_search {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
}*/
.global_search {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 5px;
  width: 323px;
  height: 21px;
  /*margin-right: -28%;
  margin-top: 8px;*/
}
/*#region For Alternate4 row colors */
/*.ui-table .ui-table-tbody > tr:nth-child(even):hover {
    background-color: #7ad2f7 !important;
    color: #333333;
    font-size: 11px !important;
    line-height: 18px !important;
    cursor: pointer !important;
}

.ui-table .ui-table-tbody > tr {
    background: #fff !important;
}*/

.ui-table .ui-table-tbody > tr:nth-child(even) {
    /*background-color: #e6f2f8 !important;*/
}
/*#endregion */
/*body .ui-table .ui-table-caption {
    border-bottom: 0 none;
    font-weight: 700;
    padding-bottom: 0px;
    padding-top: 0px;
}*/
/*p-table making header padding null*/
/*-------------------Icons Styles-------------------*/
/*body .ui-table .ui-table-thead > tr > th {
    padding: 3px 0px 1px 7px !important;
    border: 0.5px solid #9ed9f7 !important;
    font-weight: bold !important;
    color: #0253af !important;
    font-size: 11px !important;
    background-color: #dfeff7 !important;
    line-height: 18px !important;
    cursor: pointer !important;
}

.ui-table .ui-table-tbody > tr > td {
    padding: 1px 0px 0px 6px !important;
}*/

@-webkit-keyframes trans1 {
    from {
        transform: translate3d(53px,0,0);
    }

    to {
        transform: translate3d(-250px,0,0);
    }
}

@-moz-keyframes trans1 {
    from {
        transform: translate3d(53px,0,0);
    }

    to {
        transform: translate3d(-250px,0,0);
    }
}

@keyframes trans1 {
    from {
        transform: translate3d(53px,0,0);
    }

    to {
        transform: translate3d(-250px,0,0);
    }
}

@-webkit-keyframes trans2 {
    from {
        transform: translate3d(-160px,0,0);
    }

    to {
        transform: translate3d(53px,0,0);
    }
}

@-moz-keyframes trans2 {
    from {
        transform: translate3d(-160px,0,0);
    }

    to {
        transform: translate3d(53px,0,0);
    }
}

@keyframes trans2 {
    from {
        transform: translate3d(-160px,0,0);
    }

    to {
        transform: translate3d(53px,0,0);
    }
}

@-webkit-keyframes trans3 {
    from {
        transform: translate3d(53px,0,0);
    }

    to {
        transform: translate3d(-220px,0,0);
    }
}

@-moz-keyframes trans3 {
    from {
        transform: translate3d(53px,0,0);
    }

    to {
        transform: translate3d(-220px,0,0);
    }
}

@keyframes trans3 {
    from {
        transform: translate3d(53px,0,0);
    }

    to {
        transform: translate3d(-220px,0,0);
    }
}

.loader-outer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #fff;
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility 0.5s, opacity 0.3s linear;
    -moz-transition: visibility 0.5s, opacity 0.3s linear;
    transition: visibility 0.5s, opacity 0.3s linear;

    &.hide {
        visibility: hidden;
        opacity: 0;
    }

    .loader-container {
        height: 100%;
        width: 100%;
        display: table;

        .loader-inner {
            vertical-align: middle;
            height: 100%;
            display: table-cell;

            .loader {
                width: 80px;
                margin: auto;
                position: relative;
                height: 60px;

                .mask {
                    position: absolute;
                    overflow: hidden;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    border-radius: 5px;

                    .plane {
                        background: #DD1B16;
                        width: 400%;
                        height: 100%;
                        position: absolute;
                    }
                }

                #top {
                    width: 53px;
                    height: 15px;
                    left: 20px;
                    -webkit-transform: skew(-15deg, 0);
                    -moz-transform: skew(-15deg, 0);
                    transform: skew(-15deg, 0);

                    .plane {
                        -webkit-animation: trans1 1.3s ease-in infinite 0s backwards;
                        -moz-animation: trans1 1.3s ease-in infinite 0s backwards;
                        animation: trans1 1.3s ease-in infinite 0s backwards;
                    }
                }

                #middle {
                    width: 33px;
                    height: 15px;
                    left: 20px;
                    top: 15px;
                    -webkit-transform: skew(-15deg, 40deg);
                    -moz-transform: skew(-15deg, 40deg);
                    transform: skew(-15deg, 40deg);

                    .plane {
                        transform: translate3d(0px,0,0);
                        background: #DD1B16;
                        -webkit-animation: trans2 1.3s linear infinite 0.3s backwards;
                        -moz-animation: trans2 1.3s linear infinite 0.3s backwards;
                        animation: trans2 1.3s linear infinite 0.3s backwards;
                    }
                }

                #bottom {
                    width: 53px;
                    height: 15px;
                    top: 30px;
                    -webkit-transform: skew(-15deg, 0);
                    -moz-transform: skew(-15deg, 0);
                    transform: skew(-15deg, 0);

                    .plane {
                        -webkit-animation: trans3 1.3s ease-out infinite 0.7s backwards;
                        -moz-animation: trans3 1.3s ease-out infinite 0.7s backwards;
                        animation: trans3 1.3s ease-out infinite 0.7s backwards;
                    }
                }

                p.loader-text {
                    color: #000;
                    position: absolute;
                    top: 42px;
                    font-family: 'MoonHouse';
                    font-size: 16px;

                    span {
                        color: #DD1B16;
                    }
                }
            }
        }
    }
}


::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 0px;

    &:hover {
        background: #cccccc;
    }

    &:active {
        background: #888888;
    }
}

::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 0px;

    &:hover {
        background: #666666;
    }

    &:active {
        background: #333333;
    }
}

::-webkit-scrollbar-corner {
    background: transparent;
}

/*#region ramesh alli */

.main-content {
    padding: 25px;
    -webkit-box-flex: 1;
    min-height: calc(100vh - (46px + 28px));
    overflow: hidden;
    background-color: #fff !important;
}

.app.blue .breadcrumb a {
    color: #3E50B4;
    font-family: 'Poppins' !important;
    font-size: 12px !important;
    letter-spacing: 0.5px !important;
}

.b_stng {
    font-weight: bolder;
    font-family: 'Poppins';
    font-size: 12px;
    color: #000;
    letter-spacing: 0.5px;
}



fieldset.scheduler-border {
    border: 1px solid #0080cf !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
    display: block;
    width: auto;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: poppins;
    font-size: 18px;
    color: #005f9a;
    letter-spacing: 0.5px;
}


.heading_1 {
    font-family: 'Poppins';
    font-size: 18px;
    letter-spacing: 1px;
    color: #000;
    font-weight: bold;
    padding-bottom: 1.5rem;
    padding-top: 0.5rem;
}

.col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
    font-family: 'Poppins';
    letter-spacing: 0.7px;
    color: #000;
}

.small, small {
    font-size: 67%;
    font-weight: 400;
    letter-spacing: 0.1px;
}


/*.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 0rem 0rem 0.2rem 0.5rem !important;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
  width: 10rem !important;
}*/

/*.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 0rem 1rem !important;
  font-family: 'Poppins' !important;
  font-size: 11px !important;
}*/

/*.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 0rem 1rem !important;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
    line-height: 1;
    padding-bottom: 0.2rem !important;
    font-family: 'Poppins' !important;
    letter-spacing: 0.5px !important;
}*/

/*.form-control-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 0 !important;
  height: 2.7rem !important;
  letter-spacing: 0.5px;
  font-family: 'Poppins' !important;
}*/

.col-form-label {
    padding-top: 0;
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

/*.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0rem !important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  height: 2.7rem !important;
}*/

.app.grey .breadcrumb a {
    color: #3E50B4;
    font-family: 'Poppins' !important;
    font-size: 12px !important;
    letter-spacing: 0.5px !important;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border: unset;
  border-width: 0 0 1px 0;
  padding: 0%;
 
}
/*.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;
  font-family: 'roboto' !important;
  font-size: 19px !important;
  letter-spacing: 0.5px !important;
  color: black !important;
}*/
tr {
  border: 1px solid #9ed9f7;
  text-align: left;
  padding: 0 !important;
  padding-left: 1rem !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    /*text-align: left;*/
    /*border: 1px solid #e9ecef;*/
    /*border-width: 0 0 1px 0;*/
    /*padding: 1rem 1rem;*/   
}

@media only screen and (max-width:600px) {
    .breadcrumb-item + .breadcrumb-item {
        padding-left: .5rem;
        font-family: 'roboto' !important;
        font-size: 14px !important;
        letter-spacing: 0.5px !important;
        color: black !important;
    }
}

.app.grey .btn-primary {
    color: #fff;
    background-color: #3E50B4;
    border-color: #3E50B4;
    width: 8rem !important;
    font-family: 'Poppins';
    font-size: 11px !important;
    letter-spacing: 0.5px !important;
}

.app.green .btn-primary {
    color: #fff;
    background-color: #3E50B4;
    border-color: #3E50B4;
    width: 8rem !important;
    font-family: 'Poppins' !important;
    font-size: 11px !important;
    letter-spacing: 0.5px !important;
}

.app.green .menu-wrapper .card a {
    color: #ffffff !important;
    border-bottom: 1px solid rgba(0, 120, 106, 0.5);
    box-shadow: 0 1px 0 0 rgb(255 255 255 / 10%);
    font-family: 'Poppins' !important;
    font-size: 13px !important;
    letter-spacing: 0.5px !important;
}

.p-autocomplete {
    width: 225px;
    height: 25px;
    margin-top: 0.9rem;
}

.p-inputtext {
    margin: 0;
    width: 240px;
  
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #9ed9f7;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #495057;
  background: #dfeff7;
  transition: box-shadow 0.2s;
  font-size: 11px !important;
  letter-spacing: 0.5px !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #a6d5fa;
  line-height: 18px !important;
  font-size: 11px !important;
}
  /*#endregion */
  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #6eb2e4 !important;
    color: white !important;
  }

  fieldset {
    legend {
      color: red
    }

    padding: 20px;
    border: solid 1px red;

    .mat-form-field {
      padding: 10px 0;
    }
  }

  .btn {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .breadcrumb {
    margin-top: -2%;
  }

  .large_text {
    width: 20%;
  }

  .align {
    text-align: right;
  }

  .p-datatable .p-datatable-header {
    background-color: #dfeff7 !important;
    color: #495057;
    border: 1px solid #a6a6a6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }

  tr:nth-child(even) {
    background-color: #dfeff7 !important; /* Set the background color for even rows */
  }
  /* Style for odd rows */
  tr:nth-child(odd) {
    background-color: #ffffff; /* Set the background color for odd rows */
  }

tr:nth-child(even):hover {
  background-color: #008494 !important; /* Set the background color for even rows */
  color:white !important;
}
 
  /* Hover styles */
tr:hover {
  background-color: #008494 !important; /* Set the background color when hovering */
  color: white !important;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #9ed9f7 !important;
  border-width: 0 0 1px 0;
  font-weight: 700 !important;
  color: #0253af !important;
  background-color: #dfeff7 !important;
  transition: box-shadow 0.2s;
  border-right: 1px solid #9ed9f7 !important;
  font-size: 11px !important;
}

  .app.green .wrapper, .app.green .wrapper-inner {
    background: #a3d0e4;
    color: #565656;
  }

  .card_a {
    color: #333;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
  }


.p-table tr:nth-of-type(17) {
  background-color: #F0F0F0;
}


.app.green .nav-tabs.custom {
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
  width: 100%;
  cursor:pointer;
}
.nav.nav-tabs.custom.top .nav-link.active, .nav.nav-tabs.custom.top .nav-link :hover {
  color: #FFFFFF;
  background-color: #00c5b4 !important;
  border-color: #FFFFFF;
  cursor: pointer;
}

/*.p-datatable-resizable > .p-datatable-wrapper {
  overflow-x: auto;
  white-space: nowrap; 
  width: 1000px;
}*/
